import { Box, Typography } from '@material-ui/core'
import translator from 'i18next'
import React, { useMemo, useState } from 'react'

import type { CampaignFailDetails } from '../../../pages/Campaigns/legacy/common/CampaignFailDetails'
import { AdServerErrorMessage } from '../../../pages/Campaigns/legacy/view/common/AdServerErrorMessage'
import type {AddedImage, IImage, IMAGE_GROUP, TypedImageRequirements} from './types'
import { IMAGE_TYPE} from './types'
import useStyles from './styles'

interface ImageCategoryViewerProps {
  images: Array<IImage>
  title: string
  group: IMAGE_GROUP
  types: Array<IMAGE_TYPE>
  allowedResolutions: Array<TypedImageRequirements>
  campaignFailDetails?: CampaignFailDetails
}

export const ImageCategoryViewer: React.FC<ImageCategoryViewerProps> = ({
  images,
  allowedResolutions,
  group,
  types,
  title,
  campaignFailDetails,
}) => {
  const classes = useStyles()

  const getOwnImages = (allImages: Array<AddedImage | IImage>) => {
    return allImages.filter((image) => image.group === group).filter((image) => types.includes(image.type))
  }

  const isImageError = useMemo(
    () => images.some((image) => image.url === campaignFailDetails?.failedResource),
    [images, campaignFailDetails]
  )

  const [displayedImages] = useState<Array<IImage | AddedImage>>(getOwnImages(images))
  const getResolutionsDetails = () => allowedResolutions.map(({ height, width }) => `${width}px/${height}px`).join(', ')

  // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  const resolutionsDetails = useMemo(() => getResolutionsDetails(), [allowedResolutions])

  return (
    <Box mt={8} key={resolutionsDetails} component='div'>
      <Typography variant='subtitle1' gutterBottom>
        {`${title} (${resolutionsDetails})`}
      </Typography>
      {isImageError && (
        <Box mb={4}>
          <AdServerErrorMessage
            FAQHref={3}
            message={translator.t(`campaigns.adServerErrorDescription.${campaignFailDetails!.failureMessage}`)}
          />
        </Box>
      )}
      <Box>
        {displayedImages.map((image) => {
          const isFailedResource = image.url === campaignFailDetails?.failedResource

          return (
            <Box id='image-key' key={'keyStub' in image ? image.keyStub : image.url}>
              <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                <Box
                  className={
                    types.includes(IMAGE_TYPE.HORIZONTAL)
                      ? classes.imageContainerHorizontal
                      : classes.imageContainerSquare
                  }
                >
                  <img {...(isFailedResource ? { className: classes.failed } : {})} alt={image.url} src={image.url} />
                </Box>
                <Typography
                  data-testid={image.id || ('file' in image && image.file.name)}
                  className={classes.removingTitle}
                  color='primary'
                  variant='caption'
                />
              </Box>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
