import type { Location as HistoryLocation  } from 'history'
import type { FC } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'

import { UsersApi } from '../../../api/users/users'
import { FullscreenLoader } from '../../../common/components/Loaders/FullscreenLoader'
import { isUserLoadingSelector, loggedUserRoleSelector, userLoading, userSuccess } from '../../store/reducers/auth'
import { TenantsLoader } from '../../../pages/Tenants/Components/TenantsLoader'
import { Routes } from '../routes'
import { INITIAL_LOCATION_PATH } from '../../constants'
import { historyPushWithLocale } from '../../history'
import { LocalStorage } from '../../localStorage'
import { switchLocale } from '../hooks/useLocales'

interface PrivateRouteProps {
  path: string
  exact: boolean
}

export const PrivateRoute: FC<PrivateRouteProps> = ({ children, ...rest }) => {
  /* we 100% have token in this component, but no idea about role */
  const userRole = useSelector(loggedUserRoleSelector)
  const userIsLoading = useSelector(isUserLoadingSelector)
  const dispatch = useDispatch()
  const currentPath = window.location.pathname

  const authorize = async () => {
    try {
      dispatch(userLoading())
      const user = await UsersApi.getSelf()
      switchLocale(user.preferences?.language!)
      dispatch(userSuccess(user))
    } catch (error) {
      LocalStorage.set(INITIAL_LOCATION_PATH, currentPath)
      historyPushWithLocale(Routes.LOGIN)
    }
  }

  const redirect = (location: HistoryLocation) => {
    return (
      <Redirect
        to={{
          pathname: Routes.LOGIN,
          state: { from: location },
        }}
      />
    )
  }

  useEffect(() => {
    if (!userRole && !userIsLoading) {
      // prettier-ignore
      (async () => {
        await authorize()
      })()
    }
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [userRole, userIsLoading])

  if (userRole) {
    return (
      <TenantsLoader>
        <Route {...rest} render={({ location }) => (userRole ? children : redirect(location))} />
      </TenantsLoader>
    )
  } else {
    return <FullscreenLoader from={'Loading user role in private route'} />
  }
}
