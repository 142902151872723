
import axios from '../../core/axios'
import { BinaryLoader } from '../utils/BinaryLoader'
import type {AddedAMPHTML, AMPHTML} from '../../core/type'
import AMPHTMLIFrameTransformer from '../../libs/amphtml/parsers/AMPHTMLIFrameTransformer'

export class AMPHTMLWorker {
  static async create(addedAMPHTML: AddedAMPHTML, adId: number) {
    try {
      const formData = new FormData()

      formData.append('image', addedAMPHTML.image as Blob )
      formData.append('file', addedAMPHTML.file)
      formData.append('ad', adId.toString())

      const { data } = await axios.post('/ad-files', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      return { ...data, file: addedAMPHTML.file }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error while creating AMPHTML ad', error)
    }
  }

  static delete = async (id: number) => {
    try {
      await axios.delete(`/ad-files/${id}`, {
        headers: { Accept: 'application/json' },
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error while deleting AMPHTML ad')
      // @ts-ignore

      return Promise.reject(error.response.data.detail)
    }
  }

  static async update(newFiles: Array<AMPHTML | AddedAMPHTML>, oldFiles: Array<AMPHTML | AddedAMPHTML>, adId: number) {
    if (newFiles.length === 0 && oldFiles.length === 0) {
      return []
    }

    if (newFiles.length === 0 && oldFiles.length > 0) {
      await Promise.all(oldFiles.map((file) => this.delete(file.id!)))
    }

    if (newFiles.length > 0 && oldFiles.length === 0) {
      return Promise.all(newFiles.map((file) => this.create(file as AddedAMPHTML, adId)))
    }

    if (newFiles.length > 0 && oldFiles.length > 0) {
      const filesToDelete = oldFiles.filter((oldFile) => !newFiles.some((newFile) => newFile.id === oldFile.id))
      await Promise.all(filesToDelete.map((file) => this.delete(file.id!)))

      return Promise.all(
        newFiles.map((file) => {
          if (file.id) {
            return file
          } else {
            return this.create(file as AddedAMPHTML, adId)
          }
        })
      )
    }
  }

  static copyList(files: Array<AMPHTML | AddedAMPHTML>) {
    try {
      const newPromiseArr: any = files.map(async (file) => {
        if ('file' in file) {
          return Promise.resolve(file)
        } else {
          const binaryFile = await BinaryLoader.byUrl(file.url)
          const pngBlob =  await new AMPHTMLIFrameTransformer(binaryFile).getPNGBlob()

          return {
            id: null,
            url: file.url,
            file: binaryFile,
            name: file.name,
            type: file.type,
            image: pngBlob,
          }
        }
      })

      return Promise.all(newPromiseArr)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error while getting images')

      return Promise.reject(error)
    }
  }
}
